import '../styles/welcome-container.css'

const WelcomeInfo = () => {
    return (
        <div>
            <div className = "d-none d-lg-block">
                <div className = "welcome-container">
                    <div className = "welcome-content">
                        <p className = "welcome-content-title">Diseño personalizado<br/> para tu espacio<span className="yellow-dot">.</span></p>
                        <p className = "welcome-content-text">
                            Bienvenido a Espacio Díaz, donde convertimos tus 
                            ideas en muebles y ambientes únicos. Creamos soluciones 
                            a medida para hogares, oficinas, locales comerciales y más.
                            <br/><span className = "fw-bold">Tu visión, nuestro compromiso.</span>
                        </p>
                        <div className = "explore-btn">
                            <a className = "nav-link navbar-content explore-btn-text p-0" aria-current="page" href = "#services">Explorar servicios</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "welcome-container-phone">
                    <div className = "welcome-content">
                        <p className = "welcome-content-title-phone mb-2">Diseño personalizado<br/> para tu espacio<span className="yellow-dot-phone">.</span></p>
                        <p className = "welcome-content-text-phone mb-2">
                            Bienvenido a Espacio Díaz, donde convertimos tus 
                            ideas en muebles y ambientes únicos. Creamos soluciones 
                            a medida para hogares, oficinas, locales comerciales y más.
                            <br/><span className = "fw-bold">Tu visión, nuestro compromiso.</span>
                        </p>
                        <div className = "explore-btn-phone">
                            <a className = "nav-link navbar-content explore-btn-text-phone p-0" aria-current="page" href = "#services">Explorar servicios</a>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WelcomeInfo;