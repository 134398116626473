import '../styles/welcome.css';
import welcome01 from '../img/welcome01.jpeg';
import welcome02 from '../img/Welcome0002.png';
import welcome03 from '../img/welcome03.jpeg';
import WelcomeInfo from '../components/welcome_info';

const Welcome = () =>{
    return (
        <div className='w-100' id="welcome">
            <div className = "d-none d-lg-block w-100">
                <div className = "welcome">
                    <div id="carouselExampleControls" className="carousel slide espacio_diaz_img" data-bs-ride="carousel">
                        <div className="carousel-inner h-100">
                            <div className="carousel-item active" data-bs-interval="4000">
                                <img src={welcome01} className="d-block w-100" alt="kitchen01"/>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <img src={welcome02} className="d-block w-100" alt="kitchen02"/>
                            </div>
                            <div className="carousel-item" data-bs-interval="4000">
                                <img src={welcome03} className="d-block w-100" alt="kitchen03"/>
                            </div>
                        </div>
                        <div className = "row">
                            <div className ="col"></div>
                            <div className ="col"></div>
                            <div className ="col p-0">                        
                                <WelcomeInfo /> 
                            </div>                        
                        </div>
                        {/*<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button> */}
                    </div>
                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "welcome-phone">
                    <div id="carouselExampleControls" className="carousel slide espacio_diaz_img h-100" data-bs-ride="carousel">
                        <div className="carousel-inner h-100">
                            <div className="carousel-item active h-100">
                                <img src={welcome01} className="d-block w-100 h-75" alt="kitchen01"/>
                            </div>
                            <div className="carousel-item h-100">
                                <img src={welcome02} className="d-block w-100 h-75" alt="kitchen02"/>
                            </div>
                            <div className="carousel-item h-100">
                                <img src={welcome03} className="d-block w-100 h-75" alt="kitchen03"/>
                            </div>
                        </div>
                        <div className = "row">
                            <div className ="col"></div>
                            <div className ="col"></div>
                            <div className ="col p-0">                        
                                <WelcomeInfo /> 
                            </div>                        
                        </div>                        
                    </div>
                </div>
            </div>
        </div>        
    );
}

export default Welcome;