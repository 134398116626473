import '../styles/portfolio.css'

const PortfolioSection = ({label, icon, selectedIcon, active, onClick}) => {
    return (
        <div className = "w-[5.8125rem] h-[4.8875rem]">
            <div className = {active ? "portfolio-section-active" : "portfolio-section"} onClick={onClick}>
                <div className = "d-flex justify-content-between h-100 flex-column p-2 align-items-center">                    
                    <img src = {active ? selectedIcon : icon} alt='icon' height={40} width={45} className = "mt-1"/>               
                    <p 
                        className = "portfolio-section-title mb-1 mt-1" 
                        style = {{color: active ? '#fff' : '#26282E', fontWeight: active ? 600 : 400 }}>
                        {label}
                    </p>
                </div>
            </div>
        </div>
        
    );
}

export default PortfolioSection;