import React, { useState } from 'react';
import bathroomPhoto1 from '../img/bathroom/b1.jpg';
import bathroomPhoto2 from '../img/bathroom/b2.jpg';
import bathroomPhoto3 from '../img/bathroom/b3.jpg';
import bathroomPhoto4 from '../img/bathroom/b4.jpg';
import bathroomPhoto5 from '../img/bathroom/b5.jpg';
import bathroomPhoto6 from '../img/bathroom/b6.jpg';
import bathroomPhoto7 from '../img/bathroom/b7.jpg';
import bathroomPhoto8 from '../img/bathroom/b8.jpg';
import bathroomPhoto9 from '../img/bathroom/b9.jpg';

export const BathroomPortfolio = () => {

    const photos = [bathroomPhoto1,bathroomPhoto2,bathroomPhoto3,bathroomPhoto4,bathroomPhoto5,bathroomPhoto6,bathroomPhoto7,bathroomPhoto8,bathroomPhoto9];
    const [imgActive, setImgActive] = useState(bathroomPhoto1);
    const [selectedIndex, setSelectedIndex] = useState(0);
    
    const changeImg = (img, index) => {        
        setImgActive(img);
        setSelectedIndex(index);        
    }

    return (
        <div className = "row d-flex justify-content-center align-items-center">
            <div className = "col-6">                
                {                        
                        [0,1,2].map((e, index) => {
                            return (
                                <div className = "row justify-content-center" key={index}> 
                                {
                                    photos.slice(3*e,3*e+3).map((d, index2) => {                      
                                        return (
                                            <div key = {(index2+(3*e))} className = "portfolio-img" onClick={()=> changeImg(d, (index2+(3*e)))}>                            
                                                <img src = {d} alt = "" className = "portfolio-img-prev"/>
                                                <div className = {selectedIndex ===  (index2+(3*e)) ? 'portfolio-img-prev-background-active' : 'portfolio-img-prev-background'}></div>
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            );
                        })                        
                    }
            </div>
            <div className = "col-6 p-0">
                <img src={imgActive} className = "gallery-img" alt = "gallery"/>
            </div>
        </div> 
    );
}


export const BathroomPortfolioPhone = () => {

    const photos = [bathroomPhoto1,bathroomPhoto2,bathroomPhoto3,bathroomPhoto4,bathroomPhoto5,bathroomPhoto6,bathroomPhoto7,bathroomPhoto8,bathroomPhoto9];
    const [imgActive, setImgActive] = useState(bathroomPhoto1);
    const [selectedIndex, setIndex ] = useState('01'); 

    const changeImg = (img, index) => {        
        setImgActive(img);
        setIndex((index+1).toString().padStart(2,'0'));
    }

    return (                
        <div className = "row">
            <div className = "p-0 mb-4 mt-4">
                <img src={imgActive} className = "gallery-img-phone" alt = "gallery"/>
            </div>
            <p className = "gallery-info-phone">{selectedIndex}/{photos.length.toString().padStart(2,'0')}</p>
            <div className = "mt-2 container-gallery-phone">            
                <div className = "row h-100 p-0">
                    <div className = "d-flex align-items-center">
                        {photos.map((d, index) => {
                            return (
                                <div key={index} className = "w-[5.8125rem] h-[4.8875rem]">
                                    <div className = "portfolio-img-phone" onClick={()=> changeImg(d, index)}>                            
                                        <img src = {d} alt = "" className = "portfolio-img-prev-phone"/>
                                        <div className = {selectedIndex ===  ((index+1).toString().padStart(2,'0')) ? 'portfolio-img-prev-background-active' : 'portfolio-img-prev-background'}></div>
                                    </div> 
                                </div>
                            );
                        })}                                                                                            
                    </div>                                                             
                </div>
            </div> 
        </div>                                               
    );
}