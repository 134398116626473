import '../styles/rrss.css';

const Linkedin = () => {    
    return ( 
        <div className = 'rrss-container'>
            <a href='https://www.instagram.com/espacio_diaz/'>                    
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M4.02911 18H0.297321V5.98232H4.02911V18ZM2.16121 4.343C0.967902 4.343 0 3.35458 0 2.16125C8.54116e-09 1.58805 0.227698 1.03833 0.633002 0.633016C1.03831 0.227703 1.58802 0 2.16121 0C2.73439 0 3.2841 0.227703 3.68941 0.633016C4.09471 1.03833 4.32241 1.58805 4.32241 2.16125C4.32241 3.35458 3.35411 4.343 2.16121 4.343ZM17.996 18H14.2722V12.1499C14.2722 10.7556 14.2441 8.96766 12.332 8.96766C10.3918 8.96766 10.0945 10.4824 10.0945 12.0494V18H6.3667V5.98232H9.9458V7.62164H9.99804C10.4962 6.67743 11.7133 5.68098 13.5289 5.68098C17.3057 5.68098 18 8.16808 18 11.3985V18H17.996Z" fill="#E0E0E0"/>
                </svg>   
            </a>
        </div>
    );
} 

export default Linkedin;