import '../App.css'
import AboutUsInfo from '../components/about_us_info';
import ScrollFadeIn from '../scroll-fadeIn';

const AboutUs = () => {
    return (
        <div id="aboutUs">
            <div className = "d-none d-lg-block">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-4">
                            <p className = "title">Sobre Nosotros</p>
                            <p className = "content">
                                En Espacio Díaz, creemos que los espacios son mucho más 
                                que cuatro paredes. Son lugares llenos de posibilidades, 
                                emociones y funcionalidad. Como apasionados del diseño y 
                                la creación de ambientes, hemos dedicado años a convertir 
                                esas posibilidades en realidad. 
                            </p>
                            <p className = "content">
                                <span style={{fontWeight: 700}}>Nuestra misión es simple:</span> Transformar cada espacio en un 
                                reflejo auténtico de quienes lo habitan. Ya sea un rincón 
                                de tu hogar, una oficina o un local comercial en crecimiento, 
                                nuestra dedicación al diseño personalizado se traduce en muebles 
                                y espacios únicos y funcionales, que cumplen tus necesidades y 
                                resaltan tu estilo.
                            </p>
                            <p className = "content">
                                Detrás de Espacio Díaz hay un equipo apasionado, compuesto por 
                                diseñadores, arquitectos y expertos en la creación de ambientes, 
                                comprometidos en cada etapa del proceso. Nuestro enfoque en la 
                                atención al detalle, la calidad y la innovación nos ha llevado a 
                                construir relaciones duraderas con nuestros clientes y a materializar 
                                proyectos que trascienden las expectativas.
                            </p>                            
                        </div>  
                        <div className = "col-2"></div>                                              
                        <div className = "col-6">
                            <div className = "row" style = {{height: '5.5rem'}}></div>
                            <div className = "row">
                                <AboutUsInfo 
                                    index= {"01"} 
                                    title = {"Pasión por el detalle"} 
                                    content= {"Cada rincón cuenta en la creación de ambientes únicos y funcionales."}  
                                    topDivider={true}                            
                                />
                                <div style={{height: '1rem'}}></div>
                                <AboutUsInfo 
                                    index= {"02"} 
                                    title = {"Relaciones duraderas"} 
                                    content= {"Construimos conexiones genuinas con nuestros clientes, basadas en confianza y satisfacción."} 
                                    topDivider={true}                            
                                />
                                <div style={{height: '1rem'}}></div>
                                <AboutUsInfo 
                                    index= {"03"} 
                                    title = {"Innovación inspirada"} 
                                    content= {"Transformamos tu visión en soluciones innovadoras y personalizadas."} 
                                    topDivider={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "container-fluid mt-4">
                    <div className = "row">
                        <div className = "col">
                            <p className = "title-phone">Sobre Nosotros</p>
                            <p className = "content-phone">
                                En Espacio Díaz, creemos que los espacios son mucho más 
                                que cuatro paredes. Son lugares llenos de posibilidades, 
                                emociones y funcionalidad. Como apasionados del diseño y 
                                la creación de ambientes, hemos dedicado años a convertir 
                                esas posibilidades en realidad. 
                            </p>
                            <p className = "content-phone">
                                <span style={{fontWeight: 700}}>Nuestra misión es simple:</span> Transformar cada espacio en un 
                                reflejo auténtico de quienes lo habitan. Ya sea un rincón 
                                de tu hogar, una oficina o un local comercial en crecimiento, 
                                nuestra dedicación al diseño personalizado se traduce en muebles 
                                y espacios únicos y funcionales, que cumplen tus necesidades y 
                                resaltan tu estilo.
                            </p>
                            <p className = "content-phone">
                                Detrás de Espacio Díaz hay un equipo apasionado, compuesto por 
                                diseñadores, arquitectos y expertos en la creación de ambientes, 
                                comprometidos en cada etapa del proceso. Nuestro enfoque en la 
                                atención al detalle, la calidad y la innovación nos ha llevado a 
                                construir relaciones duraderas con nuestros clientes y a materializar 
                                proyectos que trascienden las expectativas.
                            </p>                                                                                   
                        </div>  
                    </div>
                    <div className = "row">
                        <ScrollFadeIn>
                            <AboutUsInfo 
                                index= {"01"} 
                                title = {"Pasión por el detalle"} 
                                content= {"Cada rincón cuenta en la creación de ambientes únicos y funcionales."} 
                                topDivider={true}                            
                            />
                            <div style={{height: '2rem'}}></div>
                            <AboutUsInfo 
                                index= {"02"} 
                                title = {"Relaciones duraderas"} 
                                content= {"Construimos conexiones genuinas con nuestros clientes, basadas en confianza y satisfacción."} 
                                topDivider={true}                            
                            />
                            <div style={{height: '2rem'}}></div>
                            <AboutUsInfo 
                                index= {"03"} 
                                title = {"Innovación inspirada"} 
                                content= {"Transformamos tu visión en soluciones innovadoras y personalizadas."} 
                                topDivider={true}
                            />
                        </ScrollFadeIn>
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default AboutUs;