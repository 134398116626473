import React, { useState } from 'react';
import PortfolioSection from '../components/portfolio_section';
import '../styles/portfolio.css';
import {KitchenPortfolio, KitchenPortfolioPhone} from '../components/kitchen_portfolio';
import { DesktopPortfolio, DesktopPortfolioPhone } from '../components/desktop_portfolio';
import { ClosetPortfolio, ClosetPortfolioPhone } from '../components/closet_portfolio';
import {BathroomPortfolio, BathroomPortfolioPhone} from '../components/bathroom_portfolio';
import {OthersPortfolio, OthersPortfolioPhone} from '../components/others_portfolio';

const Portfolio = () => {

    const [portfolio, setActive] = useState([
        {
            label: "Escritorio",
            selectedIcon: "/icons/desktop-white.png",
            icon: "/icons/desktop-black.png",
            active: true              
        },
        {
            label: "Clóset",
            selectedIcon: "/icons/furniture-white.png",
            icon: "/icons/furniture-black.png",
            active: false
        },        
        {
            label: "Cocinas",
            selectedIcon: "/icons/kitchen-white.png",
            icon: "/icons/kitchen-black.png",
            active: false
        },
        /*{
            label: "Despensas",
            icon: "",
            active: false,
            album: kitchenImg
        },*/
        {
            label: "Baños",
            selectedIcon: "/icons/bathroom-white.png",
            icon: "/icons/bathroom-black.png",
            active: false
        },
        {
            label: "Otros",
            selectedIcon: "/icons/lockers-white.png",
            icon: "/icons/lockers-black.png",
            active: false
        },
    ]);    

    const [category, setCategory] = useState('Escritorio');

    const changeSection = (label) => {        
        let index = portfolio.findIndex((p) => p.label === label)
        if( index !== -1){                        
            setActive((items) => items.map((item) => item.label === label ? {...item, active: true} : {...item, active: false}))   
            setCategory(portfolio[index].label);            
        }
    }

    return(
        <div id="portfolio">
            <div className = "d-none d-lg-block">
                <div className = "container">
                <div className = "row">
                    <div className = "portfolio-sections">
                        <div className = "row h-100">
                            <div className = "d-flex justify-content-center">        
                                {portfolio.map((p, index) => {
                                    return <PortfolioSection key={index} label={p.label} icon={p.icon} selectedIcon={p.selectedIcon} active={p.active}  onClick={()=>changeSection(p.label)}/>
                                })}                                                                      
                            </div>
                        </div>
                    </div>                               
                    <div className = "gallery mt-4 mb-4">
                        {
                            category  === 'Escritorio' ? <DesktopPortfolio />
                            :
                            category  === 'Clóset' ? <ClosetPortfolio />
                            :                            
                            category  === 'Cocinas' ? <KitchenPortfolio />
                            :
                            category  === 'Baños' ? <BathroomPortfolio />                            
                            :
                            category === 'Otros' ? <OthersPortfolio /> 
                            :
                            <div />
                        }   
                        
                    </div>
                </div>

                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "container">
                    <p className = "title-phone mb-4" style={{textAlign: 'center'}}>Portafolio</p>                    
                </div>
                <div className = "portfolio-sections-phone">
                    <div className = "row h-100">
                        <div className = "d-flex">        
                            {portfolio.map((p, index) => {
                                return <PortfolioSection key={index} label={p.label} icon={p.icon} selectedIcon={p.selectedIcon} active={p.active}  onClick={()=>changeSection(p.label)}/>
                            })}                                                                      
                        </div>
                    </div>
                </div> 

                <div className = "container">                    
                    {
                            category  === 'Escritorio' ? <DesktopPortfolioPhone />
                            :
                            category  === 'Clóset' ? <ClosetPortfolioPhone />                                                        
                            :
                            category  === 'Cocinas' ? <KitchenPortfolioPhone />
                            :
                            category  === 'Baños' ? <BathroomPortfolioPhone />                                                    
                            :
                            category === 'Otros' ? <OthersPortfolioPhone /> 
                            :
                            <div />
                        }
                </div>
                
            </div>
        </div>        
    );
}

export default Portfolio;