import React, { useState } from 'react';
import closetPhoto1 from '../img/closet/c1.jpg';
import closetPhoto2 from '../img/closet/c2.jpg';
import closetPhoto3 from '../img/closet/c3.jpg';
import closetPhoto4 from '../img/closet/c4.jpg';
import closetPhoto5 from '../img/closet/c5.jpg';
import closetPhoto6 from '../img/closet/c6.jpg';
import closetPhoto7 from '../img/closet/c7.jpg';
import closetPhoto8 from '../img/closet/c8.jpg';
import closetPhoto9 from '../img/closet/c9.jpg';

export const ClosetPortfolio = () => {

    const photos = [closetPhoto1, closetPhoto2, closetPhoto3, closetPhoto4, closetPhoto5, closetPhoto6, closetPhoto7, closetPhoto8, closetPhoto9];
    const [imgActive, setImgActive] = useState(closetPhoto1);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const changeImg = (img, index) => {        
        setImgActive(img);
        setSelectedIndex(index);        
    }
    
    return (
        <div className = "row d-flex justify-content-center align-items-center">
            <div className = "col-6 ">                
                {                        
                    [0,1,2].map((e, index) => {
                        return (
                            <div className = "row justify-content-center" key={index}> 
                            {
                                photos.slice(3*e,3*e+3).map((d, index2) => {                      
                                    return (
                                        <div key = {(index2+(3*e))} className = "portfolio-img" onClick={()=> changeImg(d, (index2+(3*e)))}>                            
                                            <img src = {d} alt = "" className = "portfolio-img-prev"/>
                                            <div className = {selectedIndex ===  (index2+(3*e)) ? 'portfolio-img-prev-background-active' : 'portfolio-img-prev-background'}></div>
                                        </div>
                                    );
                                })
                            }
                            </div>
                        );
                    })                        
                }
            </div>
            <div className = "col-6 p-0">
                <img src={imgActive} className = "gallery-img" alt = "gallery"/>
            </div>
        </div> 
    );
}

export const ClosetPortfolioPhone = () => {

    const photos = [closetPhoto1, closetPhoto2, closetPhoto3, closetPhoto4, closetPhoto5, closetPhoto6, closetPhoto7, closetPhoto8, closetPhoto9];
    const [imgActive, setImgActive] = useState(closetPhoto1);
    const [selectedIndex, setIndex ] = useState('01'); 

    const changeImg = (img, index) => {        
        setImgActive(img);
        setIndex((index+1).toString().padStart(2,'0'));
    }

    return (
        <div className = "row">
            <div className = "p-0 mb-4 mt-4">
                <img src={imgActive} className = "gallery-img-phone" alt = "gallery"/>
            </div>
            <p className = "gallery-info-phone">{selectedIndex}/{photos.length.toString().padStart(2,'0')}</p>
            <div className = "mt-2 container-gallery-phone">
                <div className = "row h-100 p-0">
                    <div className = "d-flex align-items-center">
                        {photos.map((d, index) => {
                            return (
                                <div key={index} className = "w-[5.8125rem] h-[4.8875rem]">
                                    <div className = "portfolio-img-phone" onClick={()=> changeImg(d, index)}>                            
                                        <img src = {d} alt = "" className = "portfolio-img-prev-phone"/>
                                        <div className = {selectedIndex ===  ((index+1).toString().padStart(2,'0')) ? 'portfolio-img-prev-background-active' : 'portfolio-img-prev-background'}></div>
                                    </div> 
                                </div>
                            );
                        })}                        
                    </div>
                </div>
            </div>            
        </div> 
    );
}

