import './App.css';
import Footer from './components/footer';
import Navbar from './components/navbar';
import Welcome from './sections/welcome';
import AboutUs from './sections/about_us';
import Portfolio from './sections/portfolio';
import InterestData from './sections/interest_data';
import Spacer from './components/spacer';
import Contact from './sections/contact';
import Services from './sections/services';

function App() {
  return (
    <div className="App">
      <Navbar />
      <header className = "App-header">
        <Welcome />        
        <div className="container-fluid">          
          <div className = "row">                        
            <div className = "container">
              <Spacer />
              <AboutUs />
              <Spacer />
              <Services />
              <Spacer />
              <Portfolio />              
              <Spacer />
              <InterestData />
              <Spacer />
              <Contact />
              <Spacer />              
            </div>            
          </div>
        </div> 
      </header>
      <Footer/>               
    </div>
  );
}

export default App;
