import '../styles/services.css';

const Services = () => {
    return (
        <div id="services">
            <div className = "d-none d-lg-block">
                <div className = "container">                    
                    <p className = "title mb-5" style = {{textAlign: 'center'}}>Nuestros servicios</p>                                                           
                    <div className = "row">
                        <div className = "col-4 mt-4">
                            <div className = "d-flex container justify-content-center mb-4">
                                <div className = "services-container">
                                    <img src = '/icons/furniture.png' className = "mt-5" height={120} alt='furniture'/>
                                    <p className = "services-info-phone mt-3">Muebles de línea plana</p>
                                </div>                        
                            </div>                
                        </div>
                        <div className = "col-4 mt-4">
                            <div className = "d-flex container justify-content-center mb-4">
                                <div className = "services-container">
                                    <img src = '/icons/custom.png' className = "mt-5" height={120} alt='furniture'/>
                                    <p className = "services-info-phone mt-3">Remodelaciones</p>
                                </div>                        
                            </div> 
                        </div>
                        <div className = "col-4 mt-4">
                            <div className = "d-flex container justify-content-center mb-4">
                                <div className = "services-container">
                                    <img src = '/icons/tools.png' className = "mt-5" height={120} alt='furniture'/>
                                    <p className = "services-info-phone mt-3">Servicios de construcción</p>
                                </div>                        
                            </div>                    
                        </div>                       
                    </div>
                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "container">
                    <p className = "title-phone mb-4" style={{textAlign: 'center'}}>Nuestros Servicios</p>                    
                </div>
                <div className = "row">
                    <div className = "d-flex container justify-content-center mb-4">
                        <div className = "services-container-phone">
                            <img src = '/icons/furniture.png' className = "mt-4" height={100} alt='furniture'/>
                            <p className = "services-info-phone mt-3">Muebles de línea plana</p>
                        </div>                        
                    </div> 
                    <div className = "d-flex container justify-content-center mb-4">
                        <div className = "services-container-phone">
                            <img src = '/icons/custom.png' className = "mt-4" height={100} alt='furniture'/>
                            <p className = "services-info-phone mt-3">Remodelaciones</p>
                        </div>                        
                    </div> 
                    <div className = "d-flex container justify-content-center mb-4">
                        <div className = "services-container-phone">
                            <img src = '/icons/tools.png' className = "mt-4" height={100} alt='furniture'/>
                            <p className = "services-info-phone mt-3">Servicios de construcción</p>
                        </div>                        
                    </div>                    
                </div>
            </div>

        </div>
    );
}

export default Services;