import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

const ScrollFadeIn = ({children}) => {
    const elementRef = useRef(null);

    useEffect(() => {
        const element = elementRef.current;
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if(entry.isIntersecting){
                    gsap.fromTo(
                        element,
                        {opacity: 0, y: 50},
                        {opacity: 1, y: 0, duration: 2, ease: 'power2.out'}
                    );
                    //observer.unobserve(element!);
                }
            });
        });
        
        observer.observe(element);

        return ()=> {
            if(element){
                observer.unobserve(element);
            }
        }
    }, []);

    return <div ref={elementRef} className = "w-full flex flex-col">{children}</div>
};

export default ScrollFadeIn;