import '../App.css'
import '../styles/contact.css';
import Spacer from '../components/spacer';
import ScrollFadeIn from '../scroll-fadeIn';
import hvpv from '../img/trust/logo1.jpg';
import trainfes from '../img/trust/logo2.jpg';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {

    const form = useRef();
    const fullNameRef = useRef();
    const emailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();
    const [emailStatus, setEmailStatus] = useState(0);
    const [sendingEmail, setSendingEmail] = useState(false);

    const sendEmail = async (event) => {
        event.preventDefault();
        const fullName = event.target[0].value;
        const email = event.target[1].value;
        const phone = event.target[2].value;
        const message = event.target[3].value;
        
        setSendingEmail(true);
        setEmailStatus(0);
        console.log(form.current)
        await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID, 
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID, 
            {
                fullName: fullName,
                email: email,
                phone: phone,
                message: message
            },
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then((result) => {
            console.log(result.text)
            setSendingEmail(false);
            setEmailStatus(2);
        }, (error) => {
          console.log(error.text);
            setSendingEmail(false);
            setEmailStatus(1);
        });           
    }

    return (
        <div id="contact">            
            <ScrollFadeIn>
                <div className = "d-none d-lg-block">
                    <div className = "container mb-5">
                        <p className = "title mb-5" style = {{textAlign: 'center'}}>Contacto</p>
                        <Spacer />
                        <div className = "contact-container">
                            <div className = "row">
                                <div className = "col-6">
                                    <div className = "row">
                                        <p className = "contact-clients-title">Confían en<br/> Espacio Díaz</p>
                                    </div>
                                    <div className = "row contact-logos-container d-flex justify-content-center align-items-center w-100">
                                        <div className = "col w-100">
                                            <img src = {hvpv} alt='hpvv' height={80} />
                                            <img src = {trainfes} alt='trainfes' height={80} />
                                        </div>
                                    </div>
                                    <div className = "row">
                                        <p className = "contact-info">
                                            Escríbenos a <span>contacto@espaciodiaz.com</span> <br/> 
                                            o hablemos por el número <a className = "contact-info text-decoration-none" href="https://api.whatsapp.com/send?phone=56993300490&text=¡Hola! me gustaría cotizar"><span>+569 9330 0490</span></a>
                                        </p>
                                    </div>
                                </div>
                                <div className = "col-6">
                                    <div className = "contact-form">
                                        <div className = "d-flex flex-column">
                                            <p className = "contact-instruction mb-4">Completa el formulario y te contactaremos a la brevedad</p>
                                            <form ref={form} onSubmit={sendEmail}>
                                                <div className="mb-3">
                                                    <input ref={fullNameRef} type="text" className="form-control input-contact-form" id="name" aria-describedby="name" placeholder='Nombre y apellido' required/>                                        
                                                </div>
                                                <div className="mb-3">
                                                    <input ref={emailRef} type="email" className="form-control input-contact-form" id="email" aria-describedby="name" placeholder='Correo electrónico' required/>                                        
                                                </div>
                                                <div className="mb-3">
                                                    <input ref={phoneRef} type="phone" className="form-control input-contact-form" id="name" aria-describedby="name" placeholder='Teléfono +(56)' required/>                                        
                                                </div>
                                                <div className="mb-3">
                                                    <textarea ref={messageRef} type="text" className="form-control input-contact-form" id="comments" aria-describedby="phone" placeholder='Me gustaría...' required/>                                        
                                                </div>
                                                {
                                                    emailStatus === 1 ? 
                                                    <p style={{color: 'red', fontSize: '1rem', marginBottom: '0'}}>Ocurrió un error al enviar el email. Intente nuevamente</p>
                                                    :
                                                    emailStatus === 2 ?
                                                    <p style={{color: 'green', fontSize: '1rem'}}>¡Gracias!. Un ejecutivo se pondrá en contacto con usted</p>
                                                    :
                                                    <p></p>
                                                }                                                                                                
                                                <button type="submit" className = "btn-contact mt">
                                                    {
                                                        sendingEmail ? 
                                                        <div className="spinner-border text-light" role="status">
                                                            <span className="visually-hidden">Espere por favor...</span>
                                                        </div>
                                                        :
                                                        <p>Contactar</p>
                                                    }                                                                                                        
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>                
                        </div>
                    </div>
                </div>
                <div className='d-md-block d-sm-block d-lg-none'>
                    <div className = "container">
                        <p className = "title-phone mb-4" style={{textAlign: 'center'}}>Contacto</p>                    
                        <div className = "contact-container-phone">
                            <div className = "d-flex flex-column justify-content-center align-items-center">
                                <p className = "contact-instruction mb-4">Completa el formulario y te contactaremos a la brevedad</p>
                                <form ref={form} className = "d-flex flex-column justify-content-center align-items-center" style={{width:'100%'}} onSubmit={sendEmail}>
                                    <div className="mb-3 w-100">
                                        <input ref={fullNameRef} type="text" className="form-control input-contact-form-phone" name="fullName" id="name" aria-describedby="name" placeholder='Nombre y apellido' required/>                                        
                                    </div>
                                    <div className="mb-3 w-100">
                                        <input ref={emailRef} type="email" className="form-control input-contact-form-phone" name="email" id="email" aria-describedby="email" placeholder='Correo electrónico' required/>                                        
                                    </div>
                                    <div className="mb-3 w-100">
                                        <input ref={phoneRef} type="phone" className="form-control input-contact-form-phone" name="phone" id="phone" aria-describedby="phone" placeholder='Teléfono +(56)' required/>                                        
                                    </div>
                                    <div className="mb-3 w-100">
                                        <textarea ref={messageRef} type="text" className="form-control input-contact-form-phone" name="message" id="comments" aria-describedby="message" placeholder='Me gustaría...' required/>                                        
                                    </div>
                                    <button type="submit" className = "btn-contact-phone">
                                        {
                                            sendingEmail ? 
                                            <div className="spinner-border text-light" role="status">
                                                <span className="visually-hidden">Espere por favor...</span>
                                            </div>
                                            :
                                            <p>Contactar</p>
                                        }                                                                                                        
                                    </button>                                    
                                    {
                                        emailStatus === 1 ? 
                                        <p style={{color: 'red', fontSize: '1rem'}}>Ocurrió un error al enviar el email. Intente nuevamente</p>
                                        :
                                        emailStatus === 2 ?
                                        <p style={{color: 'green', fontSize: '1rem'}}>¡Gracias!. Un ejecutivo se pondrá en contacto con usted</p>
                                        :
                                        <p></p>
                                    } 
                                </form> 
                                <div style={{height: '2rem'}}></div>
                                <div className = "row">
                                    <p className = "contact-info-phone">
                                        También puedes escribirnos a <span>contacto@espaciodiaz.com</span> <br/> 
                                        o hablemos por el número <span>+569 9330 0490</span>
                                    </p>
                                </div>                           
                            </div>
                        </div>
                        
                    </div>                
                </div>    
            </ScrollFadeIn>        
        </div>        
    );
}

export default Contact;
