import '../styles/about_us.css';

const AboutUsInfo = ({index, title, content, topDivider}) => {
    return(
        <div>
            <div className = "d-none d-lg-block">
                <div className = "container">                        
                    <div className = "row"> 
                        <div className = "row">
                            <div className = "col-2"></div>
                            <div className = "col-8">
                            {topDivider ? 
                                <div className = "divider mb-4"></div>   
                                :
                                <div></div>
                            }
                            </div>                    
                        </div>        
                        <div style={{height: '1rem'}}></div>
                        <div className = "col-2">
                            <p className = "about-us-index">{index}</p>
                        </div>
                        <div className = "col-8">                    
                            <div className = "row">
                                <p className = "about-us-title">{title}</p>
                            </div>
                            <div className = "row">
                                <p className = "about-us-content">{content}</p>
                            </div>                                             
                            
                        </div>
                    </div>            
                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "">                        
                    <div className = "row"> 
                        <div className = "row">                            
                            <div className = "col">
                            {topDivider ? 
                                <div className = "divider-phone mb-4"></div>   
                                :
                                <div></div>
                            }
                            </div>                    
                        </div>        
                        <div style={{height: '1rem'}}></div>
                        <div className = "col-2">
                            <p className = "about-us-index-phone">{index}</p>
                        </div>
                        <div className = "col-10">                    
                            <div className = "row">
                                <p className = "about-us-title-phone">{title}</p>
                            </div>
                            <div className = "row">
                                <p className = "about-us-content-phone">{content}</p>
                            </div>                                             
                            
                        </div>
                    </div>            
                </div>
            </div>
        </div>        
    );
}

export default AboutUsInfo;