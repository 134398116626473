import logo from '../img/logo-transparent.png';
import '../styles/footer.css';
import Instagram from './instagram';
import Facebook from './facebook';
import Linkedin from './linkedin';

const Footer = () => {

    return (
        <div className='footer text-center mr-0'>
            <div className= 'footer-content col-12 d-flex justify-content-center d-none d-md-flex'>
                <div className='col footer-content-mt'>
                    <img src={logo} className='footer-logo' alt='logo'/>
                </div>                
                <div className='col-8 align-self-end'>
                    <div className='col'>
                        <p>
                            <a className = 'body-text text-decoration-none' href = "#welcome">Inicio</a> <span className = "body-text"> / </span>
                            <a className = 'body-text text-decoration-none' href = "#aboutUs">Sobre nosotros</a><span className = "body-text"> / </span>                 
                            <a className = 'body-text text-decoration-none' href = "#services">Servicios</a><span className = "body-text"> / </span>
                            <a className = 'body-text text-decoration-none' href = "#portfolio">Portafolio</a><span className = "body-text"> / </span>
                            <a className = 'body-text text-decoration-none' href = "#contact">Contacto </a><span className = "body-text"> / </span>                       
                        </p>
                        <p> 
                            <svg className='copyright-logo' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                <path d="M7.41 5.355C8.37 5.4 8.6175 6.2175 8.6325 6.6H9.975C9.915 5.115 8.8575 4.2075 7.3875 4.2075C5.73 4.2075 4.5 5.25 4.5 7.605C4.5 9.06 5.1975 10.785 7.38 10.785C9.045 10.785 9.9375 9.5475 9.96 8.5725H8.6175C8.595 9.015 8.28 9.6075 7.395 9.6525C6.4125 9.6225 6 8.8575 6 7.605C6 5.4375 6.96 5.37 7.41 5.355ZM7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.1925 13.5 1.5 10.8075 1.5 7.5C1.5 4.1925 4.1925 1.5 7.5 1.5C10.8075 1.5 13.5 4.1925 13.5 7.5C13.5 10.8075 10.8075 13.5 7.5 13.5Z" fill="#E0E0E0"/>
                            </svg>
                            <span className = 'body-subtext'>
                                &nbsp;2023 Espacio Díaz. Todos los derechos reservados.
                            </span>
                        </p>                                              
                    </div>                       
                </div>
                <div className='col'>
                    <div className= 'flex-row-center footer-content-mt footer-rrss-content'>
                        <Instagram/>
                        <Facebook/>
                        <Linkedin/>
                    </div>                    
                </div>
            </div>
            <div className='footer-content col-12 d-flex justify-content-center d-flex d-sm-flex d-md-none'>
                <div className='col align-self-end'>
                    <div className= 'flex-row-center footer-content-mt footer-rrss-content mb-4 justify-content-center'>
                        <Instagram/>
                        <Facebook/>
                        <Linkedin/>
                    </div>
                    <p> 
                        <svg className='copyright-logo' xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                            <path d="M7.41 5.355C8.37 5.4 8.6175 6.2175 8.6325 6.6H9.975C9.915 5.115 8.8575 4.2075 7.3875 4.2075C5.73 4.2075 4.5 5.25 4.5 7.605C4.5 9.06 5.1975 10.785 7.38 10.785C9.045 10.785 9.9375 9.5475 9.96 8.5725H8.6175C8.595 9.015 8.28 9.6075 7.395 9.6525C6.4125 9.6225 6 8.8575 6 7.605C6 5.4375 6.96 5.37 7.41 5.355ZM7.5 0C3.36 0 0 3.36 0 7.5C0 11.64 3.36 15 7.5 15C11.64 15 15 11.64 15 7.5C15 3.36 11.64 0 7.5 0ZM7.5 13.5C4.1925 13.5 1.5 10.8075 1.5 7.5C1.5 4.1925 4.1925 1.5 7.5 1.5C10.8075 1.5 13.5 4.1925 13.5 7.5C13.5 10.8075 10.8075 13.5 7.5 13.5Z" fill="#E0E0E0"/>
                        </svg>
                        <span className = 'body-subtext'>
                            &nbsp;2023 Espacio Díaz. Todos los derechos reservados.
                        </span>
                    </p>                    
                </div>
            </div>
        </div>
    );
}

export default Footer;