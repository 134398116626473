import '../styles/rrss.css';

const Facebook = () => {    
    return ( 
        <div className = 'rrss-container'>
            <a href='https://www.instagram.com/espacio_diaz/'>                    
                <svg xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20" fill="none">
                    <path d="M9.34469 11.25L9.86325 7.63047H6.62096V5.28164C6.62096 4.29141 7.07388 3.32617 8.526 3.32617H10V0.244531C10 0.244531 8.66239 0 7.38349 0C4.71337 0 2.96806 1.73359 2.96806 4.87188V7.63047H0V11.25H2.96806V20H6.62096V11.25H9.34469Z" fill="#E0E0E0"/>
                </svg>   
            </a>
        </div>
    );
} 

export default Facebook;