import { useState } from 'react';
import CountUp from 'react-countup';
import '../styles/interest_data_info.css'
import ScrollTrigger from 'react-scroll-trigger';

const InterestDataInfo = ({amount, label}) => {

    const [counterOn, setCounterOn] = useState(false);

    return (
        <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=> setCounterOn(false)}>
            <div className = "data-container m-5" >
            <p className = "value-data mb-0 text-align-center">
                {counterOn && <CountUp start={0} end ={amount} duration={2} />}
                <span className ="plus-sign">+</span>
            </p>
            <p className = "label-data mt-0">{label}</p>            
        </div>
        </ScrollTrigger>
        
    );
}

export default InterestDataInfo;