import '../App.css'
import InterestDataInfo from '../components/interest_data_info';

const InterestData = () => {
    return (
        <div>            
            <div className = "d-none d-lg-block">
                <div className = "container mb-5">
                    <p className = "title mb-5" style = {{textAlign: 'center'}}>Datos de interés</p>
                    <div className = "row">
                        <div className = "d-flex justify-content-center">
                            <InterestDataInfo label={"Años de experiencia"} amount={30} />                  
                            <InterestDataInfo label={"Proyectos realizados"} amount={100} />
                            <InterestDataInfo label={"Clientes satisfechos"} amount={70} />                            
                        </div>                                
                    </div>
                </div>
            </div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "container">
                    <p className = "title-phone mb-4" style={{textAlign: 'center'}}>Datos de interés</p>                    
                </div>
                <div className = "row">
                    <div className = "d-flex container justify-content-center mb-5">
                        <InterestDataInfo label={"Años de experiencia"} amount={30} />
                    </div>
                    <div className = "d-flex container justify-content-center mb-5">
                        <InterestDataInfo label={"Proyectos realizados"} amount={100} />
                    </div>
                    <div className = "d-flex container justify-content-center mb-5">
                        <InterestDataInfo label={"Clientes satisfechos"} amount={70} />
                    </div>                                        
                </div>                    
            </div>
        </div>
    );
}

export default InterestData;