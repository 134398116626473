import '../styles/navbar.css';
import logo from '../img/logo-transparent.png';

const Navbar = () => {
    return (
        <div>
            <div className = "d-md-block d-sm-block d-lg-none">
                <div className = "collapse" id="collapseResponsiveMenu">
                    <div className = "" style={{backgroundColor: '#26282E'}}>
                        <div className = "flex flex-row">
                            <li className = "nav-item">
                                <a className = "nav-link navbar-content active" aria-current="page" href = "#welcome">INICIO</a>
                            </li>
                            <li className = "nav-item">
                                <a className = "nav-link navbar-content" aria-current="page" href = "#aboutUs">SOBRE NOSOTROS</a>
                            </li>
                            <li className = "nav-item">
                                <a className = "nav-link navbar-content" aria-current="page" href = "#services">SERVICIOS</a>
                            </li>
                            <li className = "nav-item navbar-content">
                                <a className = "nav-link navbar-content" aria-current="page" href = "#portfolio">PROYECTOS</a>
                            </li>                            
                            <li className = "nav-item">
                                <a className = "nav-link navbar-content" aria-current="page" href = "#contact">CONTACTO</a>
                            </li>
                        </div>
                    </div>
                </div>
                <nav className = "navbar navbar-expand-lg navbar-espacio-diaz-phone navbar-dark">
                    <div className="container-fluid">
                        <div className = "d-flex">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapseResponsiveMenu" aria-controls="collapseResponsiveMenu" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className = "logo-phone-container">
                                <img src={logo} className='logo-phone' alt='logo'/>                       
                            </div>                        
                        </div>
                        
                    </div>
                </nav>
            </div>
            <div className = "d-none d-lg-block">
                <nav className="navbar navbar-expand-lg navbar-light navbar_espacio_diaz">
                    <div className="container-fluid col-12 d-flex d-none d-md-flex h-100"> 
                        <div className = "container h-100">
                            <div className = "row h-100">
                                <div className = "d-flex col-2 justify-content-start align-items-center h-100">
                                    <img src={logo} className='footer-logo' alt='logo'/>
                                </div>
                                <div className = "col-10 mt-2">
                                    <div className = "row">
                                        <div className="col-7"></div>                        
                                        <div className="col-2">
                                            <a className = "navbar-info-text" href="https://api.whatsapp.com/send?phone=56993300490&text=¡Hola! me gustaría cotizar">+569 9330 0490</a>
                                        </div>
                                        <div className="col-2">
                                            <p className = "navbar-info-text">contacto@espaciodiaz.com</p>
                                        </div>
                                    </div>
                                    <div className = "row">
                                        <ul className = "nav justify-content-around mt-3">
                                            <li className = "nav-item">
                                                <a className = "nav-link navbar-content active" aria-current="page" href = "#welcome">INICIO</a>
                                            </li>
                                            <li className = "nav-item">
                                                <a className = "nav-link navbar-content" aria-current="page" href = "#aboutUs">SOBRE NOSOTROS</a>
                                            </li>
                                            <li className = "nav-item">
                                                <a className = "nav-link navbar-content" aria-current="page" href = "#services">SERVICIOS</a>
                                            </li>
                                            <li className = "nav-item navbar-content">
                                                <a className = "nav-link navbar-content" aria-current="page" href = "#portfolio">PROYECTOS</a>
                                            </li>                                            
                                            <li className = "nav-item">
                                                <a className = "nav-link navbar-content" aria-current="page" href = "#contact">CONTACTO</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>    
                        </div>  
                    </div>
                </nav>
            </div>
        </div>
        
    );
}

export default Navbar;
