import '../styles/rrss.css';

const Instagram = () => {    
    return ( 
        <div className = 'rrss-container'>
            <a href='https://www.instagram.com/espacio_diaz/'>                    
                <svg className='rrss-icon' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.00201 4.38503C6.44808 4.38503 4.38806 6.4455 4.38806 9C4.38806 11.5545 6.44808 13.615 9.00201 13.615C11.5559 13.615 13.616 11.5545 13.616 9C13.616 6.4455 11.5559 4.38503 9.00201 4.38503ZM9.00201 12.0003C7.35159 12.0003 6.00234 10.6548 6.00234 9C6.00234 7.3452 7.34757 5.99967 9.00201 5.99967C10.6564 5.99967 12.0017 7.3452 12.0017 9C12.0017 10.6548 10.6524 12.0003 9.00201 12.0003ZM14.8809 4.19625C14.8809 4.79471 14.399 5.27268 13.8047 5.27268C13.2064 5.27268 12.7285 4.79069 12.7285 4.19625C12.7285 3.60181 13.2104 3.11983 13.8047 3.11983C14.399 3.11983 14.8809 3.60181 14.8809 4.19625ZM17.9368 5.28874C17.8685 3.84681 17.5392 2.56956 16.4831 1.51724C15.431 0.464911 14.154 0.135557 12.7124 0.0632601C11.2267 -0.0210867 6.77334 -0.0210867 5.28756 0.0632601C3.84997 0.131541 2.57301 0.460895 1.5169 1.51322C0.460792 2.56555 0.135527 3.8428 0.063246 5.28473C-0.021082 6.77084 -0.021082 11.2251 0.063246 12.7113C0.131511 14.1532 0.460792 15.4304 1.5169 16.4828C2.57301 17.5351 3.84596 17.8644 5.28756 17.9367C6.77334 18.0211 11.2267 18.0211 12.7124 17.9367C14.154 17.8685 15.431 17.5391 16.4831 16.4828C17.5352 15.4304 17.8645 14.1532 17.9368 12.7113C18.0211 11.2251 18.0211 6.77485 17.9368 5.28874ZM16.0173 14.3058C15.7041 15.093 15.0977 15.6995 14.3066 16.0168C13.122 16.4868 10.3111 16.3783 9.00201 16.3783C7.69292 16.3783 4.87797 16.4828 3.69738 16.0168C2.91032 15.7036 2.30396 15.0971 1.98673 14.3058C1.5169 13.1209 1.62532 10.3094 1.62532 9C1.62532 7.69062 1.52091 4.87504 1.98673 3.69419C2.29994 2.90695 2.9063 2.30046 3.69738 1.98315C4.88199 1.51322 7.69292 1.62167 9.00201 1.62167C10.3111 1.62167 13.126 1.51724 14.3066 1.98315C15.0937 2.29644 15.7001 2.90293 16.0173 3.69419C16.4871 4.87906 16.3787 7.69062 16.3787 9C16.3787 10.3094 16.4871 13.125 16.0173 14.3058Z" fill="#E0E0E0"/>
                </svg>                
            </a>
        </div>
    );
} 

export default Instagram;