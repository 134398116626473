import React, { useState } from 'react';
import desktopPhoto1 from '../img/desktop/d1.jpg';
import desktopPhoto2 from '../img/desktop/d2.jpg';
import desktopPhoto3 from '../img/desktop/d3.jpg';
import desktopPhoto4 from '../img/desktop/d4.jpg';
import desktopPhoto5 from '../img/desktop/d5.jpg';
import desktopPhoto6 from '../img/desktop/d6.jpg';
import desktopPhoto7 from '../img/desktop/d7.jpg';
import desktopPhoto8 from '../img/desktop/d8.jpg';
import desktopPhoto9 from '../img/desktop/d9.jpg';


export const DesktopPortfolio = () => {

    const [imgActive, setImgActive] = useState(desktopPhoto1);
    const photos = [desktopPhoto1, desktopPhoto2, desktopPhoto3, desktopPhoto4, desktopPhoto5, desktopPhoto6, desktopPhoto7, desktopPhoto8, desktopPhoto9];
    const [selectedIndex, setSelectedIndex] = useState(0);
    const changeImg = (img, index) => {        
        setImgActive(img);
        setSelectedIndex(index);        
    }

    return (
        <div className = "row d-flex justify-content-center align-items-center">
            <div className = "col-6">                
                {                        
                    [0,1,2].map((e, index1) => {
                        return (
                            <div className = "row justify-content-center" key={e}> 
                            {
                                photos.slice(3*e,3*e+3).map((d, index2) => {                                                                                       
                                    return (
                                        <div key = {(index2+(3*e))} className = "portfolio-img" onClick={()=> changeImg(d, (index2+(3*e)))}>                            
                                            <img src = {d} alt = "" className = 'portfolio-img-prev'/>
                                            <div className = {selectedIndex ===  (index2+(3*e)) ? 'portfolio-img-prev-background-active' : 'portfolio-img-prev-background'}></div>
                                        </div>
                                    );
                                })
                            }
                            </div>
                        );
                    })                        
                }                
            </div>
            <div className = "col-6 p-0">
                <img src={imgActive} className = "gallery-img" alt = "gallery"/>
            </div>
        </div> 
    );
}

export const DesktopPortfolioPhone = () => {
    
    const photos = [desktopPhoto1, desktopPhoto2, desktopPhoto3, desktopPhoto4, desktopPhoto5, desktopPhoto6, desktopPhoto7, desktopPhoto8, desktopPhoto9];
    const [imgActive, setImgActive] = useState(desktopPhoto1);
    const [selectedIndex, setIndex ] = useState('01'); 

    const changeImg = (img, index) => {
        setImgActive(img);
        setIndex((index+1).toString().padStart(2,'0'));
    }

    return (                
        <div className = "row">
            <div className = "p-0 mb-4 mt-4">
                <img src={imgActive} className = "gallery-img-phone" alt = "gallery"/>
            </div>
            <p className = "gallery-info-phone">{selectedIndex}/{photos.length.toString().padStart(2,'0')}</p>
            <div className = "mt-2 container-gallery-phone">            
                <div className = "row h-100 p-0">
                    <div className = "d-flex align-items-center">
                        {photos.map((d, index) => {
                            return (
                                <div key={index} className = "w-[5.8125rem] h-[4.8875rem]">
                                    <div className = "portfolio-img-phone" onClick={()=> changeImg(d, index)}>                            
                                        <img src = {d} alt = "" className = "portfolio-img-prev-phone"/>
                                        <div className = {selectedIndex ===  ((index+1).toString().padStart(2,'0')) ? 'portfolio-img-prev-background-active' : 'portfolio-img-prev-background'}></div>
                                    </div> 
                                </div>
                            );
                        })}                       
                    </div>                                                             
                </div>
            </div> 
        </div>                                               
    );
}